import React from "react";
import styled from "styled-components";
import { FaLinkedinIn, FaFacebook, FaInstagram, FaGithub } from "react-icons/fa";

const ProfComponent = () => {
  return (
    <Container id="home">
        <Texts>
          <h1>
            Hello <span className="green">I'am</span>
          </h1>
          <h1 className="green">Muhammad Imran</h1>
          <h2>Cloud Engineer and Cloud Solution Architect Expert</h2>
          <p>
            I’m Azure Cloud Engineer and Microsoft Certified Azure Solution Architect Expert.
            Moreover, I am also Microsoft Certified Trainer (MCT) and Innovative Educator (MIE).
            Currently designing, developing and administering cloud solutions by using azure services.
            Also providing trainings to IT professionals with hands on labs for Azure Cloud.
          </p>
          <button><a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1F9kLOTOWpTd9zEGbwIrG74Ir7z0oXWYy/view?usp=sharing">Download CV</a></button>
          &nbsp;
          <button><a target="_blank" rel="noopener noreferrer" href="https://www.upwork.com/freelancers/~011759a74703685f47">Let's talk</a></button>
          
        </Texts>
        <Profile>
          <img
            src="https://technicianstorage.blob.core.windows.net/wearshop/Projects/profilecover5.JPG"
            alt="profile"
          />
          <Social>
            <p>Check out my</p>
            <div className="social-icons">
              <span>
                <a target="_blank" rel="noopener noreferrer" aria-label="LinkedIn Profile" href="https://www.linkedin.com/in/muhammadimran-dev">
                  <FaLinkedinIn />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" aria-label="Github Profile" href="https://github.com/muhammadimran-dev">
                  <FaGithub />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" aria-label="Facebook Profile"  href="https://www.facebook.com/muhammadimran.dev1">
                  <FaFacebook />
                </a>
              </span>
              <span>
                <a target="_blank" rel="noopener noreferrer" aria-label="Instagram Profile" href="https://www.instagram.com/muhammadimran.dev">
                  <FaInstagram />
                </a>
              </span>
            </div>
          </Social>
        </Profile>
    </Container>
  );
};

export default ProfComponent;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const Texts = styled.div`
  flex: 1;
  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
  }

  button {
    padding: 0.7rem 2rem;
    margin-top: 3rem;
    cursor: pointer;
    background-color: #01be96;
    border: none;
    color: #000000;
    font-weight: bold;
    filter: drop-shadow(0px 10px 10px #01be9551);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
    a {
      color: #000000;
      text-decoration:none;
    }
  }
`;
const Social = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 660px) {
    margin-left: 10%;
  }

  p {
    font-size: 0.9rem;
    @media (max-width: 690px) {
      font-size: 0.7rem;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
      width: 2.3rem;
      height: 2rem;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      background-color: #01be96;
      position: relative;
      transition: transform 400ms ease-in-out;
      :hover {
        transform: rotate(360deg);
      }
    }

    a {
      color: #fff;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Profile = styled.div`
  img {
    width: 20rem;
    filter: drop-shadow(0px 10px 10px #01be9570);
    transition: transform 400ms ease-in-out;
    @media (max-width: 790px) {
      width: 20rem;
    }

    @media (max-width: 660px) {
      width: 100%;
    }

    @media (max-width: 400px) {
      width: 100%;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;