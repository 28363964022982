import React from 'react'
import styled from 'styled-components';

const Blog = (props) => {
    const { thumbnail, title, pubDate, description, link } = props.item;
    return (
        <Container>
            <HeaderImg>
                <img src={thumbnail} alt={title} />
            </HeaderImg>
            <Header>
                <div className="details">
                    <h2>{title}</h2>
                    <h3>{pubDate}</h3>
                </div>
            </Header>
            <Body>
            {description}
            </Body>
            <Footer>
                <button><a target="_blank" rel="noopener noreferrer" href={link}>Go to Blog</a></button>
            </Footer>
        </Container>
    )
}

export default Blog;

const Container = styled.div`
    justify-content: space-between;
    border-radius:1rem;
    background: #191923;
    padding: 1rem;
    margin: 0 auto;
    height: 28rem;
    width: 14rem;    
    cursor: pointer;
    overflow: hidden;

    @media(max-width: 380px){
        height: 27rem;
        width: 13.1rem;
    }
`
const HeaderImg = styled.div`
    align-items: center;
    justify-content: space-between;
    img{
        width: 100%;
    }
`

const Header = styled.div`
    align-items: center;
    justify-content: space-between;
    .quote{
        font-size: 3rem;
        color: #01be96;
        opacity: 0.7;
    }

    .star{
        color: #ffcd3c;
        font-size: 1.3rem;
    }
    img{
        width: 100%;
    }

    h2{
        font-size: 1.2rem;
        font-weight: 700;
        height: 4rem;
    }

    h3{
        margin-top: 1rem;
        font-size: 0.8rem;
        font-weight: 700;
    }
`
const Body = styled.div`
    font-size: 0.8rem;
    margin-top: 1rem;
    justify-content: space-between;
`
const Footer = styled.div`
    align-items: center;
    button {
        margin: 1rem;
        padding: 0.7rem 2rem;
        cursor: pointer;
        background-color: #01be96;
        border: none;
        color: black;
        font-weight: bold;
        filter: drop-shadow(0px 10px 10px #01be9551);
        :hover {
          filter: drop-shadow(0px 10px 10px #01be9570);
        }
        a {
          color: black;
          text-decoration:none;
        }
      }
`