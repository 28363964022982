import React from 'react'
//import Slider from 'react-slick';
import Certification from './Certification';
//import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';

let data = [
  {
    img: "https://images.credly.com/images/fd6bb2af-2f05-4d9b-a23e-39f8e309a82d/image.png",
    disc: "Microsoft Certified Trainer (2023-2024)"
  },
  {
    img: "https://images.credly.com/size/340x340/images/987adb7e-49be-4e24-b67e-55986bd3fe66/azure-solutions-architect-expert-600x600.png",
    disc: "Microsoft Certified: Azure Solutions Architect Expert"
  },
  {
    img: "https://images.credly.com/size/340x340/images/c3ab66f8-5d59-4afa-a6c2-0ba30a1989ca/CERT-Expert-DevOps-Engineer-600x600.png",
    disc: "Microsoft Certified: DevOps Engineer Expert"
  },
  {
    img: "https://images.credly.com/size/340x340/images/63316b60-f62d-4e51-aacc-c23cb850089c/azure-developer-associate-600x600.png",
    disc: "Microsoft Certified: Azure Developer Associate"
  },
  {
    img: "https://images.credly.com/size/340x340/images/336eebfc-0ac3-4553-9a67-b402f491f185/azure-administrator-associate-600x600.png",
    disc: "Microsoft Certified: Azure Administrator Associate"
  },
  {
    img: "https://images.credly.com/size/340x340/images/9d7dc4c0-5681-41fc-b96b-26e9157786d7/image.png",
    disc: "Designing Microsoft Azure Infrastructure Solutions"
  },
  {
    img: "https://images.credly.com/size/340x340/images/107e2eb6-f394-40eb-83d2-d8c9b7d34555/exam-az400-600x600.png",
    disc: "Designing and Implementing Microsoft DevOps Solutions"
  },
  {
    img: "https://images.credly.com/size/340x340/images/bb4156e4-c2e1-4399-b03c-af6feb7a6cc4/image.png",
    disc: "Microsoft Certified Trainer (2022-2023)"
  },
  {
    img: "https://images.credly.com/size/340x340/images/a6ea4416-4f34-4a85-bc24-eb3fe32fd241/MCT-Microsoft_Certified_Trainer-600x600.png",
    disc: "Microsoft Certified Trainer (2021-2022)"
  },
  {
    img: "https://images.credly.com/size/340x340/images/be8fcaeb-c769-4858-b567-ffaaa73ce8cf/image.png",
    disc: "Microsoft Certified: Azure Fundamentals"
  },
  {
    img: "https://images.credly.com/size/340x340/images/70eb1e3f-d4de-4377-a062-b20fb29594ea/azure-data-fundamentals-600x600.png",
    disc: "Microsoft Certified: Azure Data Fundamentals"
  },
  {
    img: "https://technicianstorage.blob.core.windows.net/wearshop/Badges/Cloud_Skills_Champion_Badge.png",
    disc: "MSUS Cloud Skills Challenge Champion"
  },
  {
    img: "https://images.credly.com/size/340x340/images/4136ced8-75d5-4afb-8677-40b6236e2672/azure-ai-fundamentals-600x600.png",
    disc: "Microsoft Certified: Azure AI Fundamentals"
  },
  {
    img: "https://ucarecdn.com/f6ad59e7-7b87-471b-acd0-95591941f659/-/format/auto/-/preview/3000x3000/-/quality/lighter/Scrum-Foundation-Professional-Certificate-SFPC_-2021_1_480x480.png",
    disc: "Scrum Foundation Professional Certificate - SFPC™"
  }
];

// var settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4,
//   slidesToScroll: 4,
//   initialSlide: 0,
//   arrows: false,
//   responsive: [
//     {
//       breakpoint: 1500,
//       settings: {
//         slidesToShow: 4,
//         slidesToScroll: 4,
//         infinite: true
//       }
//     },
//     {
//       breakpoint: 1100,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         infinite: true
//       }
//     },
//     {
//       breakpoint: 800,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//         initialSlide: 2
//       }
//     },
//     {
//       breakpoint: 650,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         centerMode: false
//       }
//     }
//   ]
// };
const SliderComp = () => {
  //const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = data.map((item, i) => (
    <Certification item={item} key={i} />
  ))
  return (
    <Container>
      {/* <Slider ref={arrowRef} {...settings}>
        {sliderProject}
      </Slider> */}
      {sliderProject}
      {/* <Buttons>
        <button
          onClick={() => arrowRef.current.slickPrev()}
          className='back' aria-label="Back"><IoIosArrowBack /></button>
        <button
          onClick={() => arrowRef.current.slickNext()}
          className='next' aria-label="Next"><IoIosArrowForward /></button>
      </Buttons> */}
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  
  @media(max-width:840px){
      width: 90%;
  }

  span{
      font-weight: 700;
      text-transform: uppercase;
  }

  h1{
      padding-top: 1rem;
      text-transform: capitalize;
  }

  .slick-list, .slick-slider, .slick-track{
    padding: 0;
}

.slick-dots{

    text-align: center;
    margin-left: 1rem;
}

.slick-dots li button:before{
    content: "";
}

.slick-dots li button{
    width: 9px;
    height: 4px;
    background: White;
    padding: 0.1rem;
    margin-top: 1rem;
    transition: all 400ms ease-in-out;
    border-radius: 50px;
}

.slick-dots li.slick-active button{
    background: #01be96;
    width: 15px;
}

.slick-dots li{
    margin: 0;
}
`
// const Buttons = styled.div`
//   button{
//     width: 2rem;
//     height: 2rem;
//     background-color: rgba(0, 0, 0, 9);
//     cursor: pointer;
//     color: #01be96;
//     border: none;
//     position: absolute;
//     top: 45%;
//     right: -1rem;
//   }

//   .back{
//     left: -1rem;
//   }
// `