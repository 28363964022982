import React, { useRef } from 'react'
import Slider from 'react-slick';
import Blog from './Blog';
import styled from 'styled-components';

let data = [
  {
    thumbnail: "https://technicianstorage.blob.core.windows.net/wearshop/Projects/azurecommunicationservice-blog.png",
    title: "Azure Communication Service",
    pubDate: "May 09, 2023 09:14",
    description: "Azure Communication Services Email is a new primitive that facilitates high volume transactional, bulk and marketing emails on Azure Communication Services platform and will enable Application-to...",
    link: "https://muhammadimran-dev.medium.com/azure-communication-services-fbc5f8d84949"
  },
  {
    thumbnail: "https://technicianstorage.blob.core.windows.net/wearshop/Projects/logicapp-blog.png",
    title: "Azure Logic App    Overview",
    pubDate: "Feb 06, 2023 02:03",
    description: "Azure Logic Apps is a cloud platform where you can create and run automated workflows with little to no code. By using the visual designer and selecting from prebuilt operations, you can quickly buil...",
    link: "https://muhammadimran-dev.medium.com/azure-logic-app-overview-c5e3e7690198"
  },
  {
    thumbnail: "https://technicianstorage.blob.core.windows.net/wearshop/Projects/chatgpt-blog.png",
    title: "Azure OpenAI (Chat GPT-3) Overview",
    pubDate: "Feb 06, 2023 12:44",
    description: "The Azure OpenAI service provides REST API access to powerful language models including the GPT-3, Codex and Embeddings model series. These models can be easily adapted to your specific task...",
    link: "https://muhammadimran-dev.medium.com/azure-openai-chat-gpt-3-overview-90650e346e58"
  }
];

var settings = {
  className: "center",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows : false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false
      }
    }
  ]
};

const SliderComp = () => {
  const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = data.map((item, i) => (
    <Blog item={item} key={i} />
  ))
  return (
    <Container>
      <Testimonials>
        <Slider ref={arrowRef} {...settings}>
          {sliderProject}
        </Slider>
      </Testimonials>
      <button><a target="_blank" rel="noopener noreferrer" href='https://muhammadimran-dev.medium.com/'>Explore More</a></button>
      {/* <Buttons>
        <button
          onClick={() => arrowRef.current.slickPrev()}
          className='back' aria-label="Back"><IoIosArrowBack /></button>
        <button
          onClick={() => arrowRef.current.slickNext()}
          className='next' aria-label="Next"><IoIosArrowForward /></button>
      </Buttons> */}
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  width: 80%;
  margin: 0 auto;

  @media(max-width:840px){
      width: 90%;
  }
  @media(max-width:360px){
    width: 100%;
    max-width: 100rem;
  }

  span{
      font-weight: 700;
      text-transform: uppercase;
  }

  h1{
      padding-top: 1rem;
      text-transform: capitalize;
  }

  button {
    margin: 1rem;
    padding: 0.7rem 2rem;
    cursor: pointer;
    background-color: #01be96;
    border: none;
    color: white;
    font-weight: bold;
    filter: drop-shadow(0px 10px 10px #01be9551);
    :hover {
      filter: drop-shadow(0px 10px 10px #01be9570);
    }
    a {
      color: black;
      text-decoration:none;
    }
  }

  .slick-list, .slick-slider, .slick-track{
    padding: 0;
  }

  .slick-dots{
      text-align: center;
  }

  .slick-dots li button:before{
      content: "";
  }

  .slick-dots li button{
      width: 9px;
      height: 4px;
      background: White;
      padding: 0.1rem;
      margin-top: 1rem;
      transition: all 400ms ease-in-out;
      border-radius: 50px;
  }

  .slick-dots li.slick-active button{
      background: #01be96;
      width: 15px;
  }

  .slick-dots li{
      margin: 0;
  }
`
const Testimonials = styled.div`
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 3rem 0;
  align-items: center;
  text-align: center;
`