import React from 'react'
import styled from 'styled-components';

const Project = (props) => {
    const { img, disc } = props.item;
    return (
        <Container className='certification'>
            <img src={img} alt="certification" />
            <div className="disc">
                <h1>Description</h1>
                <p><a target="_blank" rel="noopener noreferrer" href="https://www.credly.com/users/muhammadimran/badges">{disc}</a></p>
            </div>
        </Container>
    )
}

export default Project;

const Container = styled.div`
    height: 10rem;
    width: 10rem;
    margin: 0 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    img{
        width: 100%;
        height: 100%;
    }
    .disc{
        position: absolute;
        right: 0;
        left: 0;
        bottom: -10rem;
        text-align: left;
        padding: 0.5rem;
        background: linear-gradient(rgba(0,0,0, 0.600), rgba(0,0,0, 20));
        transition: all 400ms ease-in-out;
        h1{
            font-size: 1rem;
        }
    
        p{
            width: 90%;
            font-size: 0.8rem;
            a {
                color: white;
                text-decoration:none;
              }
        }
    }

    :hover > img{
        transform: scale(1);
    }

    :hover > .disc{
        bottom: 0;
    }

`