import React, { useRef } from 'react'
import Slider from 'react-slick';
import Project from './Project';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';

let data = [
    {
        img : "https://technicianstorage.blob.core.windows.net/wearshop/Projects/OIPMigration.jpg",
        disc : "Simplified migration, modernization, and optimization service for Azure."
    },
    {
        img : "https://technicianstorage.blob.core.windows.net/wearshop/Projects/AzureDevOps.png",
        disc : "Version controlling, reporting, automated builds, testing and release management capabilities."
    },
    {
        img : "https://technicianstorage.blob.core.windows.net/wearshop/Projects/DevOpsFlow.png",
        disc : "Support the build and release of the application from development to production."
    },
    {
        img : "https://technicianstorage.blob.core.windows.net/wearshop/Projects/ADFFlow.png",
        disc : "Azure's cloud ETL service for scale-out serverless data integration and data transformation."
    },
    {
        img : "https://technicianstorage.blob.core.windows.net/wearshop/Projects/FTPServerWatcher.png",
        disc : "Integration solution for ingesting, preparing, and transforming all your data."
    },
    {
        img : "https://technicianstorage.blob.core.windows.net/wearshop/Projects/ADB2CBusiness.png",
        disc : "Customer identity access management (CIAM) solution that enables to sign up and sign in your customers."
    },
    {
        img : "https://technicianstorage.blob.core.windows.net/wearshop/Projects/HybridIdentityAuthentication.png",
        disc : "Solution for securely share company's applications and services with business users and customers from any other organization."
    }
];

var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode : false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode : false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode : false
        }
      }
    ]
  };
const SliderComp = () => {
  const arrowRef = useRef(null);
    let sliderProject = "";
    sliderProject = data.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
      {sliderProject}
      </Slider>
      <Buttons>
        <button 
        onClick={() => arrowRef.current.slickPrev()}
        className='back' aria-label="Back"><IoIosArrowBack/></button>
        <button 
        onClick={() => arrowRef.current.slickNext()}
        className='next' aria-label="Next"><IoIosArrowForward/></button>
      </Buttons>
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    width: 2rem;
    height: 2rem;
    background-color: rgba(0, 0, 0, 9);
    cursor: pointer;
    color: #01be96;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back{
    left: -1rem;
  }
`