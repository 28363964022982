import React from 'react'
import styled from 'styled-components';
import SliderComp from './Slider';

const Projects = () => {
  return (
    <Container id='project'>
        <h1>Recent <span className="green">Projects</span></h1>
        <p>Designs and implements cloud solutions and configures DevOps pipelines to automate 
            software builds, testing, and deployment 
            that meet client needs while ensuring high performance, reliability, and security.</p>
        <SliderComp/>
    </Container>
  )
}

export default Projects;

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 3rem 0;
    text-align: center;
    position: relative;
    @media(max-width: 80%){
        width: 80%;
    }
    h1{
        font-size: 1.9rem;
    }

    p{
        width: 65%;
        margin: 0 auto;
        padding: 1rem 0;
        font-size: 0.9rem;
        @media(max-width : 500px){
            width: 90%;
        }
    }   
`